<template>
  <div class="lg_content">
    <div class="top_dat">
      <img class="top_img" src="../assets/images/login/logo.png" alt="" />
    </div>
    <p class="text_top">欢迎登录亚丁科技</p>
    <div class="center_lg">
      <div class="center_input">
        <img src="../assets/images/login/icon_ss.png" alt="" />
        <input
          v-model="phone"
          style="background: transparent"
          oninput="if(value.length>11)value=value.slice(0,11)"
          type="number"
          placeholder="请输入手机号"
        />
      </div>
      <div class="center_input">
        <img src="../assets/images/login/icon_ss.png" alt="" />
        <input
          v-model="code"
          style="background: transparent; width: 140px"
          oninput="if(value.length>6)value=value.slice(0,6)"
          type="number"
          placeholder="请输入验证码"
        />
        <div class="yanzma">
          <p v-if="isStart" @click="getCode">
            重新发送<span>({{ outNum }}s)</span>
          </p>
          <span v-else @click="getCode">获取验证码</span>
        </div>
      </div>
      <div class="footer_bottom" @click="gotoLogin">登录</div>
    </div>
   
  </div>
</template>

<script>
import { Toast } from 'vant'
import { userSendSms ,userLogin} from "../api/myORlogin";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      phone: "",
      code: "",
      outNum: 30,
      isStart: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    gotoLogin() {
        Toast.clear()
        if(!this.phone){
           return Toast('请输入手机号');
        }
        if(!this.code){
           return Toast('请输入验证码');
        }
        userLogin({
            phone:this.phone,
            code:this.code,
            openid:window.localStorage.getItem("openid")
        }).then(res=>{
          clearInterval(this.outTime);
            window.localStorage.setItem("token",res.data.token)
            this.$router.push('/my')
        })
    },
    getCode() {
        if(!this.phone){
           return Toast('请输入手机号');
        }
      userSendSms({ phone: this.phone }).then((res) => {
        if (res.code == 1) {
          this.isStart = true;
          this.setTimeInterval();
        }
      });
    },
    setTimeInterval() {
      this.outNum = 30;
      this.outTime = setInterval(() => {
        console.log("倒计时");
        if (this.outNum > 0) {
          this.outNum--;
        } else {
          this.outNum = 0;
          this.isStart = false;
          clearInterval(this.outTime);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="less">
.yanzma {
  padding-left: 24px;
  min-width: 210px;
  border-left: 1px solid #d2d2d4;
  span {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #577bb5;
  }
  p {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #999999;
  }
}
.center_input {
  padding: 0 30px;
  margin: 40px auto;
  display: flex;
  align-items: center;
  width: 600px;
  height: 88px;
  background: #f8f8f8;
  border-radius: 44px;
  img {
    margin-right: 22px;
    height: 40px;
    width: 40px;
  }
}
.center_lg {
  padding: 20px 0;
  margin: 128px auto 0 auto;
  width: 690px;
  // height: 466px;
  background: #ffffff;
  border-radius: 30px;
}
.footer_bottom {
  margin: 20px auto 40px auto;
  text-align: center;
  width: 600px;
  line-height: 88px;
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
  height: 88px;
  background: #577bb5;
  box-shadow: 0px 6px 15px 1px rgba(87, 123, 181, 0.4);
  border-radius: 44px;
}
.text_top {
  text-align: center;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 50px;
  color: #333333;
}
.lg_content {
  padding-top: 1px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, #eaf2fe 65%, #f8fafe 100%);
}
.top_dat {
  width: 400px;
  height: 366px;
  margin: 200px 175px 20px 175px;
}
.top_img {
  width: 400px;
  height: 366px;
}
</style>
